import logoQwik from './qwik-logo.svg';
import logoSvelte from './svelte-logo.svg';
import logo from './logo.svg';
import logoUI5 from './logosetUI5/logoset/O_UI5_V.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div class="flex-container">
          <img src={logoQwik} className="App-logo" alt="logo qwik" width="120px" />
          <img src={logoSvelte} className="App-logo" alt="logo svelte" width="120px" />
          <img src={logo} className="App-logo" alt="logo react" width="200px" />
          <img src={logoUI5} className="App-logo" alt="logo ui5" width="150px" />
        </div>
        {/* <p>
          Edit <code>src/App.js</code> and save to reload.
        </p> */}
        <a
          className="App-link"
          href="https://www.klingbeil.dev"
          target="_blank"
          rel="noopener noreferrer"
        >
          termanico's website - coming soooon 🥳🚀👨‍🌾
        </a>
      </header>
    </div >
  );
}

export default App;
